<script>
import calendarFormat from '@/components/assistent/components/calendarFormat'
export default {
  extends: calendarFormat,
  components: {},
  props: {
    callback: Function
  },
  data () {
    return {
      options: [
        {
          title: this.$t('message.privateTitle'),
          description: this.$t('message.privateTitlePlaceholder'),
          value: 'eventpicker',
          icon: 'birthday-cake'
        }
      ]
    }
  },
  methods: {
    setData (type) {
      return this.callback({ type: type })
    }
  }
}
</script>
<style lang="sass" scoped>
  .flix-active
    .flix-html-h3
      color: white

  .flix-html-h3
    margin-top: 0
    font-weight: bold

  .flix-icon
    font-size: 40pt
    float: left
    margin-right: 15px
    padding: 4px 0
</style>
